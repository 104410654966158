//----------------------------------------------------------//
//  SERIVICIOS
//----------------------------------------------------------//
.card-servicios {

  .ico-servicios_dh { 

    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100%;
    padding: 5px;
    background-color: $accent;
    margin-top: -5px;

  }

  &:hover {

    .ico-servicios_dh { 

      background-color: $complementary1;  

    }

  }

}
