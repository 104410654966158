//----------------------------------------------------------//
//  TIENDA
//----------------------------------------------------------//
.bg-parllax-tienda_dh {

  background-color: #a0d7d0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 0;

  .row {

  	 height: 400px;

  }

}