// --------------------------------------------------
// --------------------------------------------------
// ***** MEDIA QUERY *****
// --------------------------------------------------

// --------------------------------------------------
// Extra small small screen / phone
// --------------------------------------------------
$XSS-min:                         321px !default;

// --------------------------------------------------
// Extra small screen / phone
// --------------------------------------------------
$XS2-min:                         410px !default;

// --------------------------------------------------
// Extra small screen / phone
// --------------------------------------------------
$XS-min:                          480px !default;

// --------------------------------------------------
// Small screen / tablet
// --------------------------------------------------
$SM-min:                          576px !default;

// --------------------------------------------------
// Medium screen / desktop
// --------------------------------------------------
$MD-min:                          768px !default;

// --------------------------------------------------
// Large screen / wide desktop
// --------------------------------------------------
$LG-min:                          992px !default;

// --------------------------------------------------
// Porporcionando un máximo
// --------------------------------------------------
$XL-min:                          1200px !default;

// --------------------------------------------------
// Porporcionando un máximo alternativo 
// --------------------------------------------------
$XL2-min:                         1400px !default;

// --------------------------------------------------
// Porporcionando un máximo alternativo
// --------------------------------------------------
$XLL-min:                         1650px !default;

// --------------------------------------------------
// MENOS 1
// --------------------------------------------------
$XSS-max:                         ($XSS-min - 1) !default;
$XS2-max:                         ($XS2-min - 1) !default;
$XS-max:                          ($XS-min - 1) !default;
$SM-max:                          ($SM-min - 1) !default;
$MD-max:                          ($MD-min - 1) !default;
$LG-max:                          ($LG-min - 1) !default;
$XL-max:                          ($XL-min - 1) !default;
$XL2-max:                         ($XL2-min - 1) !default;
$XLL-max:                         ($XLL-min - 1) !default;


// --------------------------------------------------
// VALORES MIN
// Ejemplo de uso @include MQ-min(MD)
// --------------------------------------------------
@mixin MQ-min($canvas) {

  @if $canvas == XSS {
    @media screen and (min-width: $XSS-min) {
      @content;
    }
  }
  @if $canvas == XS2 {
    @media screen and (min-width: $XS2-min) {
      @content;
    }
  }
  @if $canvas == XS {
    @media screen and (min-width: $XS-min) {
      @content;
    }
  }
  @if $canvas == SM {
    @media screen and (min-width: $SM-min) {
      @content;
    }
  }
  @else if $canvas == MD {
    @media screen and (min-width: $MD-min) {
      @content;
    }
  }
  @else if $canvas == LG {
    @media screen and (min-width: $LG-min) {
      @content;
    }
  }
  @else if $canvas == XL {
    @media screen and (min-width: $XL-min) {
      @content;
    }
  }
  @else if $canvas == XL2 {
    @media screen and (min-width: $XL2-min) {
      @content;
    }
  }
  @else if $canvas == XLL {
    @media screen and (min-width: $XLL-min) {
      @content;
    }
  }

}

// --------------------------------------------------
// VALORES MAX
// Ejemplo de uso @include MQ-max(XS)
// --------------------------------------------------
@mixin MQ-max($canvas) {

  @if $canvas == XSS {
    @media screen and (max-width: $XSS-max) {
      @content;
    }
  }
  @if $canvas == XS2 {
    @media screen and (max-width: $XS2-max) {
      @content;
    }
  }
  @if $canvas == XS {
    @media screen and (max-width: $XS-max) {
      @content;
    }
  }
  @if $canvas == SM {
    @media screen and (max-width: $SM-max) {
      @content;
    }
  }
  @else if $canvas == MD {
    @media screen and (max-width: $MD-max) {
      @content;
    }
  }
  @else if $canvas == LG {
    @media screen and (max-width: $LG-max) {
      @content;
    }
  }
  @else if $canvas == XL {
    @media screen and (max-width: $XL-max) {
      @content;
    }
  }
  @else if $canvas == XL2 {
    @media screen and (max-width: $XL2-max) {
      @content;
    }
  }
  @else if $canvas == XLL {
    @media screen and (max-width: $XLL-max) {
      @content;
    }
  }

}
