//----------------------------------------------------------//
//  	COLOR
//----------------------------------------------------------//
.bg-secondary {

	background-color: $secondary !important;

}
.bg-complementary1 {

	background-color: $complementary1 !important;

}
.bg-complementary2 {

	background-color: $complementary2 !important;

}
.bg-complementary3 {

	background-color: $complementary3 !important;

}
.color-primary {

	color: $primary !important;

}
.color-secondary {

	color: $secondary !important;

}
.color-complementary1 {

	color: $complementary1 !important;

}
.color-complementary2 {

	color: $complementary2 !important;

}
.color-complementary3 {

	color: $complementary3 !important;

}
.bg-general {

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.bg-parallax_dh {

	background-attachment: fixed;

}

.font-Libre-Franklin {

	font-family: $font-family-Libre-Franklin !important;

}
.font-Ariata-Display {

	font-family: $font-family-Ariata-Display !important;

}
.font-Ariata-Stencil {

	font-family: $font-family-Ariata-Stencil !important;

}
.font-weight-light {

	font-weight: $font-weight-light !important;

}
.font-weight-bold {

	font-weight: $font-weight-bold !important;
	
}
.min-vh-dh {

	min-height: 80vh!important;

	@include MQ-max(MD) { 

		min-height: 60vh!important;
		
	}
	@include MQ-max(SM) { 

		min-height: 40vh!important;
		
	}
	@include MQ-max(XS) { 

		min-height: 30vh!important;
		
	}
	@include MQ-max(XS2) { 

		min-height: 25vh!important;
		
	}

}




//----------------------------------------------------------//
//  GENERAL
//----------------------------------------------------------//
p {

	color: $secondary;

}
section {

	position: relative;

}
.link_dh {

	color: $primary;
	text-transform: uppercase;
	padding: 5px;
	margin-bottom: 5px;
	border-bottom: solid $primary 1px;

	&:hover {

		color: $secondary;
		border-bottom-color: $secondary;

	}

}
::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
}
::-webkit-scrollbar-thumb {
    background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
    background: $primary;
}

/* Cambiamos el fondo cuando esté en active */
::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}
/* Ponemos un color de fondo y redondeamos las esquinas del track */
::-webkit-scrollbar-track {
    background: #e1e1e1;
}

/* Cambiamos el fondo cuando esté en active o hover */
::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
//----------------------------------------------------------//
//  BACKGROUND VIDEO Y SLIDER
//----------------------------------------------------------//
.video-wrapper {

  position: relative;
  overflow: hidden;

  &.bg-overlay:after {

    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($gray-900, 0.4);
    z-index: 1;

  }

  &.bg-overlay.bg-overlay_dh:after {

    opacity: 0.4;
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 30%, rgba(255,255,255,0) 62%);

  }

  video {

    object-fit: cover;
    display: block;
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0;
    left: 0;
    background: $black;

    &:-webkit-media-controls {

      display: none !important;

    }

  }

  .video-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

}
.slider-wrapper {

  position: relative;
  overflow: hidden;

  &.bg-overlay:after {

    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($gray-900, 0.4);
    z-index: 1;

  }

  &.bg-overlay.bg-overlay_dh:after {

    opacity: 0.4;
    background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 80%, rgba(0,0,0,1) 100%);

  }

}

//----------------------------------------------------------//
//  MENU
//----------------------------------------------------------//
.nav_dh {

	&.navbar-clone {

    z-index: 1007;

  }

	.navbar-nav .nav-link {

		text-transform: uppercase;
		color: $primary;
		font-family: $font-family-Libre-Franklin;
		font-weight: $font-weight-light;
		font-size: .7rem;

		&:hover {

			color: $accent;

		}

	}


	.navbar-brand {	

		@include MQ-max(LG) {	

			padding-top: 8px;
			padding-bottom: 8px

		}

		img {

			@include MQ-max(LG) {	

		  	width: 80%;	

		  }

		}

	}

}
.nav-top {
	height: 20px;
}

//----------------------------------------------------------//
//	LANGUAGE DROPDOWN
//----------------------------------------------------------//
.language-select {

	.dropdown-toggle {

		font-size: 13px;
		line-height: 13px;

	}

  .dropdown-menu {

    z-index: 100000000;
    width: auto;

  }

  .dropdown-item {

		font-size: 10px;
		line-height: 13px;
		padding: 10px 15px;

  }

}
//----------------------------------------------------------//
//	MAPA
//----------------------------------------------------------//
#map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}
.marker {
	background-image: url(/assets/img/pin.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.mapboxgl-popup {
	max-width: 200px;
}
.mapboxgl-popup-content {
	text-align: center;
	font-family: 'Open Sans', sans-serif;
}

//----------------------------------------------------------//
//  BOOSTRAP ADICIONAL
//----------------------------------------------------------//
.pr-0 {

	padding-right: 0 !important;

}
.pl-0 {

	padding-left: 0 !important;
	
}
.text-right {

	text-align: right !important;

}
.border-spacing-25 {

	border-spacing: 25px !important;

}
//----------------------------------------------------------//
//  HEADER
//----------------------------------------------------------//
.bg-titulo_dh {

  background-color: $complementary1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: relative;
  z-index: 0;

  .row {

  	min-height: 280px;

  }

  &.imagine-canvas {

  	min-height: 280px;
  	height: 280px;

  	@include MQ-max(LG) {	

  		min-height: 220px;
  		height: 220px;

		}

  	@include MQ-max(MD) {	

  		min-height: 180px;
  		height: 180px;

		}

  	@include MQ-max(SM) {	

  		min-height: 100px;
  		height: 100px;

		}

  }

}
.home {

	.navtop_dh {

    position: absolute;
    top: 15px;
    z-index: 100;
    right: 0;
    background-color: transparent !important;
    color: white;

	}

}
//----------------------------------------------------------//
//  MARCAS
//----------------------------------------------------------//
.marcas-dh {

  img {

  	-webkit-filter: grayscale(100%);
      filter: grayscale(100%);

  }

}
//----------------------------------------------------------//
//  FORMULARIO
//----------------------------------------------------------//
.form-radio {
  
  display: none;
	
	& + .label-radio {
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    cursor: pointer;
    display: inline-block;
    padding: 0 0 0 40px;
    position: relative;
    whitespace: no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
   	color: $primary;
    margin-bottom: 0;
    margin-right: 0;
	
		&::before {

   		content: "";
   		position: absolute;
   		left: 0;
   		top: 0;
   		width: 28px;
   		height: 28px;
   		border-radius: 50%;
   		border: 2px solid $primary;
   		background: white;	
		}

	}
	&:checked + .label-radio {

		&::before {

   		border-color: $primary;
   		background: $primary;

		}

	}

}
.form-floating {
	textarea {
		min-height: 120px;
	}
	.radio-item {
		display: inline-block;
		margin-right: 15px;
		@include MQ-max(MD) {	
			display: block;

		}
	}
}
//----------------------------------------------------------//
//  FOOTER
//----------------------------------------------------------//
.bg-detail {

	height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.footer-widgets {

	font-family: $font-family-Libre-Franklin;
	font-weight: $font-weight-light;
	font-size: 12px;
	line-height: 16px;

	.uil {

		color: white !important;

	}

	img {

		width: 100%;

	}

}
.horario_dh {

	font-family: $font-family-Ariata-Display;
	font-weight: $font-weight-bold;
	font-size: 18px;
	line-height: 20px;

	span {

		font-family: $font-family-Libre-Franklin;
		font-weight: $font-weight-bold;
		font-size: 16px;
		line-height: 18px;

	}

}

