//----------------------------------------------------------//
//  PUBLICIDAD
//----------------------------------------------------------//
.contador_dh {

  display: inline-block;
  margin: 10px;
  border-radius: 100%;
  border: solid $accent 2px;
  width: 25%;
  padding: 4rem 2rem;

}
.contador-small {

  height: 250px;
  width: 250px;
  padding: 2.6rem 2rem;

}

.contador-big {

  height: 350px;
  width: 350px;

}