//----------------------------------------------------------//
//  BLOG
//----------------------------------------------------------//
.img-blog_dh {

  width: 100%;
  height: auto;

}
.parent {

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(4, 1fr);
	/*grid-template-rows: repeat(20, 1fr);*/
	grid-column-gap: 0px;
	grid-row-gap: 0px;

}
.item-blog_dh {

	margin: 10px;

}

.post-meta li:before {

    content: "";
    display: inline-block;
    width: 0.2rem;
    height: 100%;
    border-radius: 0;
    margin: 0 0.4rem 0;
    vertical-align: 0.15rem;
    background: #14232e;
    opacity: .5;

}

.div1 { grid-area: 1 / 1 / 2 / 3; }
.div2 { grid-area: 1 / 3 / 2 / 4; }
.div3 { grid-area: 2 / 1 / 3 / 2; }
.div4 { grid-area: 2 / 2 / 3 / 3; }
.div5 { grid-area: 2 / 3 / 3 / 4; }
.div6 { grid-area: 3 / 1 / 4 / 2; }
.div7 { grid-area: 3 / 2 / 4 / 4; }
.div8 { grid-area: 4 / 1 / 5 / 2; }
.div9 { grid-area: 4 / 2 / 5 / 3; }
.div10 { grid-area: 4 / 3 / 5 / 4; }
.div11 { grid-area: 5 / 1 / 6 / 3; }
.div12 { grid-area: 5 / 3 / 6 / 4; }
.div13 { grid-area: 6 / 1 / 7 / 2; }
.div14 { grid-area: 6 / 2 / 7 / 3; }
.div15 { grid-area: 6 / 3 / 7 / 4; }
.div16 { grid-area: 7 / 1 / 8 / 2; }
.div17 { grid-area: 7 / 2 / 8 / 4; }
.div18 { grid-area: 8 / 1 / 9 / 2; }
.div19 { grid-area: 8 / 2 / 9 / 3; }
.div20 { grid-area: 8 / 3 / 9 / 4; }
.div21 { grid-area: 9 / 1 / 10 / 3; }
.div22 { grid-area: 9 / 3 / 10 / 4; }
.div23 { grid-area: 10 / 1 / 11 / 2; }
.div24 { grid-area: 10 / 2 / 11 / 3; }
.div25 { grid-area: 10 / 3 / 11 / 4; }
.div26 { grid-area: 11 / 1 / 12 / 2; }
.div27 { grid-area: 11 / 2 / 12 / 4; }
.div28 { grid-area: 12 / 1 / 13 / 2; }
.div29 { grid-area: 12 / 2 / 13 / 3; }
.div30 { grid-area: 12 / 3 / 13 / 4; }
.div31 { grid-area: 13 / 1 / 14 / 3; }
.div32 { grid-area: 13 / 3 / 14 / 4; }
.div33 { grid-area: 14 / 1 / 15 / 2; }
.div34 { grid-area: 14 / 2 / 15 / 3; }
.div35 { grid-area: 14 / 3 / 15 / 4; }
.div36 { grid-area: 15 / 1 / 16 / 2; }
.div37 { grid-area: 15 / 2 / 16 / 4; }
.div38 { grid-area: 16 / 1 / 17 / 2; }
.div39 { grid-area: 16 / 2 / 17 / 3; }
.div40 { grid-area: 16 / 3 / 17 / 4; }
.div41 { grid-area: 17 / 1 / 18 / 3; }
.div42 { grid-area: 17 / 3 / 18 / 4; }
.div43 { grid-area: 18 / 1 / 19 / 2; }
.div44 { grid-area: 18 / 2 / 19 / 3; }
.div45 { grid-area: 18 / 3 / 19 / 4; }
.div46 { grid-area: 19 / 1 / 20 / 2; }
.div47 { grid-area: 19 / 2 / 20 / 4; }
.div48 { grid-area: 20 / 1 / 21 / 2; }
.div49 { grid-area: 20 / 2 / 21 / 3; }
.div50 { grid-area: 20 / 3 / 21 / 4; }