//----------------------------------------------------------//
//  LOGO HERO
//----------------------------------------------------------//
.logo-hero_dh {

	position: absolute;
  width: 100%;
  z-index: 2;
  height: 80px;
  margin-top: 10px;

  img {

  	display: block;
    margin: auto;
    width: auto;
    height: 100%;

  }

  @include MQ-max(SM) { 

    display: none !important;

  }

}

//----------------------------------------------------------//
//  ENTRADAS / ACCESOS
//----------------------------------------------------------//
.bg-accesos_dh {

  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;

  &::before {

    content: "";
    position: absolute;
    width: 100%;
    height: 80%;
    background-color: white;
    top: 0;
    left: 0;

  }

}
.acceso-group_dh {

  padding-bottom: 35px;
  position: relative;
  width: 100%;

  .acceso-info {

    padding: 0 25px;
    position: absolute;
    bottom: 0;
    z-index: 2;

  }

  .portfolio-item > img {

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  }


}
.acceso_dh- {

  &1 {

    margin-top: -80px;

    .acceso-info {  

      bottom: -130px; 

      @include MQ-max(MD) { 
        
        bottom: -80px;
        padding: 0 8px;

      }

      .titulo-acceso {    

        font-size: 60px; 
        line-height: 64px; 

         @include MQ-max(MD) {  

          font-size: 30px; 
          line-height: 34px;  

        }

      }

    }

    .portfolio-item > img {

      width: 90%;

      @include MQ-max(MD) { 

        width: 60%;

      }

    }

  }

  &2 {

    margin-top: 300px;

    @include MQ-max(MD) {
    
      text-align: left;
      margin-top: 240px;
    
    }

    .acceso-info {  

      position: absolute;
      left: 0;
      top: 0;
      text-align: left;

      @include MQ-max(MD) { 

        top: initial;
        bottom: 0;
        bottom: -80px;
        padding: 0 8px;
        text-align: right;
        width: 100%;

      }

      .titulo-acceso {    

        font-size: 30px;  

        @include MQ-max(MD) {  

          font-size: 30px; 
          line-height: 34px;  

        }

      }

    }

    .portfolio-item > img {

      width: 60%;
      float: right;

      @include MQ-max(MD) { 

        width: 60%;

      }

    }

  }

  &3 {

    margin-top: -240px;

    @include MQ-max(MD) { 

      margin-top: 230px;

    }

    .acceso-info {  

      bottom: -60px; 

      @include MQ-max(MD) { 
        
        bottom: -80px;
        padding: 0 8px;

      }

      .titulo-acceso {    

        font-size: 30px; 


        @include MQ-max(MD) {  

          font-size: 30px; 
          line-height: 34px;  

        } 

      }

    }

    .portfolio-item > img {

      width: 60%;

      @include MQ-max(MD) { 

        width: 60%;

      }

    }

  }

  &4 {

    margin-top: 240px;

    @include MQ-max(MD) { 

      margin-top: 280px;

    }

    .acceso-info {  

      bottom: -70px;  

      @include MQ-max(MD) { 

        bottom: -80px;
        padding: 0 8px;
        text-align: right;
        width: 100%;

      }

      .titulo-acceso {    

        font-size: 60px;
        line-height: 64px;


        @include MQ-max(MD) {  

          font-size: 30px; 
          line-height: 34px;  

        }

      }


    }

    .portfolio-item > img {

      width: 90%;

      @include MQ-max(MD) { 

        width: 60%;
        float: right;

      }

    }

  }

}
//----------------------------------------------------------//
//  RELEVANTE ENTRETENIMIENTO
//----------------------------------------------------------//
.bg-relevante_dh {

  position: relative;

  &::before {

    content: "";
    position: absolute;
    width: 100%;
    height: 75%;
    background-color: $complementary1;
    bottom: 0;
    left: 0;
    z-index: -1;

  }

}
//----------------------------------------------------------//
//  SOMOS
//----------------------------------------------------------//
.border-somos_dh {

  border-left: solid 1px $secondary;
  padding-left: 25px;

}
