//----------------------------------------------------------//
//  ANIMACION
//----------------------------------------------------------//
.dzsparallaxer--target{

  box-shadow: 0 0 5px rgba(0,0,0,0.15);
  width: 100%; height: 100%;

}
.dzsparallaxer {
	background-color: transparent !important;
}
//----------------------------------------------------------//
//  IMAGINE
//----------------------------------------------------------//
.imagine-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
  margin: 0;
  height: 600px;
  height: 100vh;
}

.imagine-animated {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.imagine-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.imagine-wrapper.imagine-horizontal {
  height: 100%;
  width: auto;
}

.imagine-auto {
  height: auto;
}

.imagine-auto .imagine-content {
  position: relative;
  display: block;
  height: auto;
}

.imagine-auto .imagine-content .imagine-content-container {
  position: relative;
  display: block;
}

.imagine-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;
  z-index: 99;
}

.imagine-content.imagine-content-center > .imagine-content-container {
  display: table-cell;
  vertical-align: middle;
}

.imagine-content.imagine-content-top > .imagine-content-container {
  display: table-cell;
  vertical-align: top;
}

.imagine-content.imagine-content-bottom > .imagine-content-container {
  display: table-cell;
  vertical-align: bottom;
}

.imagine-canvas {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.imagine-layer {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

.imagine-layer:nth-child(1) {
  z-index: 9;
}

.imagine-layer:nth-child(2) {
  z-index: 8;
}

.imagine-layer:nth-child(3) {
  z-index: 7;
}

.imagine-layer:nth-child(4) {
  z-index: 6;
}

.imagine-layer:nth-child(5) {
  z-index: 5;
}

.imagine-layer:nth-child(6) {
  z-index: 4;
}

.imagine-layer:nth-child(7) {
  z-index: 3;
}

.imagine-layer:nth-child(8) {
  z-index: 2;
}

.imagine-layer:nth-child(9) {
  z-index: 1;
}

.imagine-background {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotateZ(0deg) translateZ(0);
            transform: rotateZ(0deg) translateZ(0);
  }
  25% {
    -webkit-transform: rotateZ(180deg) translateZ(0);
            transform: rotateZ(180deg) translateZ(0);
  }
  50% {
    -webkit-transform: rotateZ(180deg) translateZ(0);
            transform: rotateZ(180deg) translateZ(0);
  }
  75% {
    -webkit-transform: rotateZ(360deg) translateZ(0);
            transform: rotateZ(360deg) translateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translateZ(0);
            transform: rotateZ(360deg) translateZ(0);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotateZ(0deg) translateZ(0);
            transform: rotateZ(0deg) translateZ(0);
  }
  25% {
    -webkit-transform: rotateZ(180deg) translateZ(0);
            transform: rotateZ(180deg) translateZ(0);
  }
  50% {
    -webkit-transform: rotateZ(180deg) translateZ(0);
            transform: rotateZ(180deg) translateZ(0);
  }
  75% {
    -webkit-transform: rotateZ(360deg) translateZ(0);
            transform: rotateZ(360deg) translateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translateZ(0);
            transform: rotateZ(360deg) translateZ(0);
  }
}

@-webkit-keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.imagine-loader {
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  border: 4px solid #Fff;
  top: 50%;
  left: 50%;
  border-radius: 3px;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  -webkit-animation: loader 2s infinite ease;
          animation: loader 2s infinite ease;
  z-index: 99;
}

.imagine-loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  -webkit-animation: loader-inner 2s infinite ease-in;
          animation: loader-inner 2s infinite ease-in;
}

/*# sourceMappingURL=../../maps/imagine/imagine.css.map */

//----------------------------------------------------------//
//  PORTFOLIO
//----------------------------------------------------------//
.portfolio-item {

  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  .portfolio-item-text {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

  .portfolio-item-title {
    font-size: 3.5rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  .portfolio-item-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
  }

  .portfolio-item-title,
  .portfolio-item-subtitle {
    color: white;
    margin: 0;
  }


  & > img {
    opacity: 0.75;
    z-index: 1;
    display: block;
    position: relative;
    width: 100%;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 30s ease, opacity 0.5s ease,  -webkit-filter 0.5s ease, -webkit-transform 30s ease, opacity 0.5s ease,  filter 0.5s ease;
            transition: transform 30s ease, opacity 0.5s ease,  filter 0.5s ease;
  }

  &:hover {

    & > img {

    opacity: 1;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);

    }

  }

  &.solid-item {

    & > img {

      opacity: 1;

    }

  }

  &.gray-item {

    & > img {

      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);

    }

    &:hover {

      & > img { 

        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);  

      }

    }

  }

}


